<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    color="#bdc3ce"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <v-divider class="mb-1" />

    <v-list dense nav class="pa-0">
      <v-list-item>
        <v-row no-gutters class="ma-0">
          <div style="width: 100%" class="d-flex">
            <a href="https://www.nuovocircondarioimolese.it/" target="_blank">
              <v-img
                contain
                src="@/assets/Logo NCI 3.png"
                height="100"
                width="220"
              />
            </a>
          </div>
        </v-row>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <template v-slot:append>
      <div style="margin-left:8px; !important">
        ver. 1.09
      </div>
      <div style="margin-left:8px; !important">
        &copy; 2022, powered by
        <a href="https://www.genegis.net" style="color: darkred">GeneGIS GI</a>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import Utils from "@/utils/Utils";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "dashboard",
        to: "/",
      },
      {
        icon: "mdi-car-multiple",
        title: "veicoli",
        to: "/pages/veicoli",
      },
      {
        icon: "mdi-toolbox",
        title: "attrezzature.titolo",
        to: "/pages/attrezzature",
      },
      {
        icon: "mdi-account-group",
        title: "fornitori.titolo",
        to: "/pages/fornitori",
      },
      {
        icon: "mdi-calendar-alert",
        title: "scadenze",
        to: "/pages/scadenze",
      },
      {
        icon: "mdi-ev-station",
        title: "rifornimenti.titolo",
        to: "/pages/rifornimenti",
      },
      {
        icon: "mdi-cash-multiple",
        title: "emissionebuoni",
        to: "/pages/emissionebuoni",
      },
      {
        icon: "mdi-file-chart",
        title: "report",
        to: "/pages/report",
      },
      {
        icon: "mdi-cog-outline",
        title: "configurazioni",
        to: "",
        group: "/pages/configurazioni",
        children: [
          {
            title: "Gestione tipologiche",
            icon: "mdi-bell",
            to: "tipologiche",
          },
          {
            title: "Enti",
            icon: "mdi-bell",
            to: "enti",
          },
          {
            title: "Servizi",
            icon: "mdi-bell",
            to: "servizi",
          },
          {
            title: "Poli",
            icon: "mdi-bell",
            to: "poli",
          },
          {
            title: "Ubicazioni",
            icon: "mdi-bell",
            to: "ubicazioni",
          },
          {
            title: "Capitoli spesa",
            icon: "mdi-bell",
            to: "capitolispesa",
          },
          {
            title: "Profilazione utenti",
            icon: "mdi-bell",
            to: "profilazioneutenti",
          },
        ],
      },
      {
        title: "Notifiche",
        icon: "mdi-bell",
        to: "/pages/notifiche",
      },
      {
        icon: "mdi-calendar-check",
        title: "Prenotazioni",
        to: "",
        group: "/pages/prenotazioni",
        children: [
          {
            title: "Calendario Prenotazioni",
            icon: "mdi-calendar-check",
            to: "calendarioprenotazioni",
          },
          {
            title: "Gestione Prenotazioni",
            icon: "mdi-calendar-check",
            to: "gestioneprenotazioni",
          },
        ],
      },      
      /*
      {
        title: "Gestione Prenotazioni",
        icon: "mdi-calendar-check",
        to: "/pages/prenotazioni",
      },
      */
      /*
        {
          icon: 'mdi-account',
          title: 'user',
          to: '/pages/user',
        },
        {
          title: 'rtables',
          icon: 'mdi-clipboard-outline',
          to: '/tables/regular-tables',
        },
        {
          title: 'typography',
          icon: 'mdi-format-font',
          to: '/components/typography',
        },
        {
          title: 'google',
          icon: 'mdi-map-marker',
          to: '/maps/google-maps',
        },
        */
       /*
      {
        title: "icons",
        icon: "mdi-chart-bubble",
        to: "/components/icons",
      },
      */
      /*
        {
          title: 'Notification',
          icon: 'mdi-bell',
          to: '/components/notifications',
        },
        */
    ],
  }),

  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  methods: {
    mapItem(item) {
      var isVis = true;
      if (this.$store.state.permissions != null) {
        switch (item.title) {
          case "dashboard":
            isVis = this.isPageVisible("R_DASHBOARD");
            break;
          case "veicoli":
            isVis = this.isPageVisible("R_VEICOLI");
            break;
          case "attrezzature":
          case "attrezzature.titolo":
            isVis = this.isPageVisible("R_ATTREZZATURE");
            break;
          case "fornitori":
          case "fornitori.titolo":
            isVis = this.isPageVisible("R_FORNITORI");
            break;
          case "scadenze":
            isVis = this.isPageVisible("R_SCADENZE");
            break;
          case "emissionebuoni":
            isVis = this.isPageVisible("R_BUONI");
            break;
          case "report":
            isVis = this.isPageVisible("R_REPORT");
            break;
          case "configurazioni":
            isVis = this.isPageVisible("W_CONFIGURAZIONI");
            break;
          case "Notifiche":
            isVis = this.isPageVisible("R_NOTIFICHE");
            break;
          case "rifornimenti":
            isVis = this.isPageVisible("R_RIFORNIMENTI");
            break;
          case "Prenotazioni":
            isVis = this.isPageVisible("W_PRENOTAZIONI");
            break;
        }
      }

      //var asdf = this.$store.state.roles;
      //var asdf2 = this.$store.state.permissions;

      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
        visible: isVis,
      };
    },
    isPageVisible(pageName) {
        var isVis = false;
        for (let i = 0; i < this.$store.state.permissions.length; i++) {
          var perm = this.$store.state.permissions[i];
          if (perm.CCode == pageName) {
            isVis = true;
            break;
          }
        }
        return isVis;
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
